


































































































































































import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { collections } from '../firebase'
import { mapState } from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown';
import { slider, slideritem } from 'vue-concise-slider'

@Component({
  components: {
    VueCountdown
  },
  computed: {
    ...mapState([
      'upcomingFestEvents',
      'activePromotion',
      'slider',
      'slideritem',
      'enteredGiveaways'
    ])
  }
})
export default class PromotionView extends Vue {

  submitting = false
  done = false
  valid = false
  loading = false

  activePromotion!: any
  errorMessage = ''
  private enteredGiveaways!: Array<string>

  info = {
    promotionId: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    displayName: ''
  }

  sliderOptions = {
    currentPage: 0,
    itemAnimation: true,
    slidesToScroll: 1,
    loopedSlides: 2,
    loop: true,
    autoplay: 5000
  }

  get promoEndDateDisplay() {

    if (this.activePromotion != null) {
      var d = new Date(this.activePromotion.endDate.seconds * 1000); // Epoch
      
      return d.toLocaleString()
    }

    return ""
  }

  get endDateSeconds() {
    const now = new Date()
    var d = new Date(this.activePromotion.endDate.seconds * 1000)

    return d.getTime() - now.getTime()
  }

  get startDateSeconds() {
    const now = new Date()
    var d = new Date(this.activePromotion.startDate.seconds * 1000)

    return d.getTime() - now.getTime()
  }

  private async created() {
    const id = this.$route.params.id
    await this.$store.dispatch('loadPromotionDetails', id)

    if (this.activePromotion != null) {
      document.title = this.activePromotion.name + ' by ' + this.activePromotion.organization.name + ' - Beerscovery'
      const tag = document.createElement('meta');
      tag.setAttribute('name', 'description')
      tag.setAttribute('content', this.activePromotion.description)
      // tag as router controlled so that router removes when route changes
      tag.setAttribute('data-vue-router-controlled', '');
    }

    this.info.promotionId = this.activePromotion.id
    // TODO: 404 for Promotion not found

    this.loading = false
  }

  alreadyEnteredGiveaway() {
    return this.enteredGiveaways.includes(this.activePromotion.id)
  }

  isPromoCompleted() {
    const now = new Date()
    const endDate = new Date(this.activePromotion.endDate.seconds * 1000)

    return now > endDate
  }

  isPromoDateActive() {
    const now = new Date()
    const startDate = new Date(this.activePromotion.startDate.seconds * 1000)
    const endDate = new Date(this.activePromotion.endDate.seconds * 1000)

    return now >= startDate && now <= endDate
  }

  async submit() {
    this.errorMessage = ''

    if (this.valid) {
      this.submitting = true

      this.info.displayName = this.info.firstName + " " + this.info.lastName[0]

      const emailEntry = await collections.giveawayEntries
        .where("email", "==", this.info.email)
        .where("promotionId", "==", this.activePromotion.id).get()

      var phoneCheck = false

      if (this.info.phoneNumber.length > 0) {
        const phoneEntry = await collections.giveawayEntries
          .where("phoneNumber", "==", this.info.phoneNumber)
          .where("promotionId", "==", this.activePromotion.id).get()

        phoneCheck = phoneEntry.docs.length > 0
      }

      if (emailEntry.docs.length > 0 || phoneCheck) {
        this.errorMessage = "This e-mail and/or phone number has already entered the giveaway. Only 1 entry per person."
      }
      else {
        await collections.giveawayEntries.add({
          ...this.info,
          date: new Date()
        })

        this.done = true

        if (this.$route.query.app) {
          this.$router.push('/promotions?submitted=true')
        }
        else {
          setTimeout(() => { this.$router.push('/discover') }, 8000)
        }

        this.$store.commit('enterGiveaway', this.activePromotion.id)
      }

      this.submitting = false
    }
  }

  private reset() {
    this.info.email = ''
    this.info.firstName = ''
    this.info.lastName = ''
    this.info.phoneNumber = ''
    this.info.displayName = ''
    this.done = false
    this.errorMessage = ''
  }

  get rules() {
    return {
      name: [
        function notEmpty(val) {
          if (val == null || val.length === 0) {
            return 'Name cannot be empty.'
          }
          return true
        }
      ],
      phoneNumber: [
        function notEmpty(val) {
          if (val == null || val.length === 0) {
            return 'Name cannot be empty.'
          }
          return true
        }
      ],
      email: [
        function notEmpty(val) {
          if (val == null || val.length === 0) {
            return 'E-mail cannot be empty.'
          }

          if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val)) {
            return "Invalid e-mail"
          }
          return true
        }
      ],

    }
  }

}
