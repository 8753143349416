


































































import OrgBanner from '../components/OrgBanner.vue'
import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { Organization } from '../model/Organization'
import { mapState } from 'vuex'

@Component({
  components: {
    OrgBanner
  },
  computed: {
    ...mapState([
      'activeOrg',
      'activeTab',
      'orgLinks'
    ])
  }
})
export default class OrgLinks extends Vue {

  private loading = true

  private activeOrg!: Organization
  orgLinks!: [any]

  private async created () {
    const id = this.$route.params.id
    await this.$store.dispatch('loadOrg', id)
    await this.$store.dispatch('loadOrgLinks')
    this.loading = false

    console.log("RYAN LINKS: ", this.orgLinks)

    if (this.activeOrg != null) {
      document.title = this.activeOrg.name + ' Links - Beerscovery'
      const tag = document.createElement('meta');
      tag.setAttribute('name', 'description')
      tag.setAttribute('content', `Browse ${this.activeOrg.name} Links`)
      // tag as router controlled so that router removes when route changes
      tag.setAttribute('data-vue-router-controlled', '');
    }

    // TODO: 404 for Org not found
  }

  feedLinkClicked(url) {
    this.$store.commit('updateFeedLinkCount', this.activeOrg.id)

    window.open(url, '_blank');
  }

  linkClicked(link) {
    link.webClicks += 1
    this.$store.commit('updateLinkCount', link.id)

    window.open(link.url, '_blank');
  }
}
