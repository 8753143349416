











































































































































































import OrgBanner from "../components/OrgBanner.vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Organization } from "../model/Organization";
import { Menu } from "../model/Menu";
import { mapState } from "vuex";

@Component({
  components: {
    OrgBanner,
  },
  computed: {
    ...mapState(["activeOrg", "activeTab", "activeMenu"]),
  },
  methods: {
    GetPackageTypes(beer) {
      if (beer.packageTypes != null && beer.packageTypes.length > 0) {
        return beer.packageTypes;
      } else if (beer?.packageType !== "") {
        return [beer.packageType];
      } else {
        return [];
      }
    },
  },
})
export default class MenuView extends Vue {
  private loading = true;

  private activeOrg!: Organization;
  activeMenu!: Menu;

  private async created() {
    const id = this.$route.params.id;
    await this.$store.dispatch("loadMenu", id);

    this.loading = false;

    if (this.activeMenu != null) {
      document.title = "🔎 Menu - " + this.activeMenu.name;
      const tag = document.createElement("meta");
      tag.setAttribute("name", "description");
      tag.setAttribute("content", `${this.activeMenu.organization.name} Menu`);
      // tag as router controlled so that router removes when route changes
      tag.setAttribute("data-vue-router-controlled", "");
    }

    // TODO: 404 for Org not found
  }

  feedLinkClicked(url) {
    this.$store.commit("updateFeedLinkCount", this.activeOrg.id);

    window.open(url, "_blank");
  }

  linkClicked(link) {
    link.webClicks += 1;
    this.$store.commit("updateLinkCount", link.id);

    window.open(link.url, "_blank");
  }

  dateDisplay(date: any) {
    var d = new Date(1970, 0, 1); // Epoch
    d.setSeconds(date.seconds);

    return d.toLocaleDateString() + " @ " + d.toLocaleTimeString();
  }
}
