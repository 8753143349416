



















































import EventsDisplay from '../components/EventsDisplay.vue'
import OrgBanner from '../components/OrgBanner.vue'
import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { Organization } from '../model/Organization'
import { mapState } from 'vuex'

@Component({
  components: {
    EventsDisplay,
    OrgBanner
  },
  computed: {
    ...mapState([
      'activeOrg',
      'activeTab',
      'orgEvents',
      'orgEventsSort',
    ])
  }
})
export default class OrgEvents extends Vue {

  private loading = true
  private hasEvents = true

  private activeOrg!: Organization

  private async created () {
    const id = this.$route.params.id
    await this.$store.dispatch('loadOrg', id)
    await this.loadMore()
    this.loading = false

    if (this.activeOrg != null) {
      document.title = this.activeOrg.name + ' Events - Beerscovery'
      const tag = document.createElement('meta');
      tag.setAttribute('name', 'description')
      tag.setAttribute('content', `Browse ${this.activeOrg.name} Events`)
      // tag as router controlled so that router removes when route changes
      tag.setAttribute('data-vue-router-controlled', '');

      if (this.activeOrg.numEvents == 0) {
        this.hasEvents = false
      }
    }

    // TODO: 404 for Org not found
  }

  private async loadMore () {
    return this.$store.dispatch('loadOrgEvents')
  }

  private setSort (val) {
    this.$store.dispatch('sortOrgEvents', val)
  }

}
