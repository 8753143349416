
































































































import OrgBanner from "../components/OrgBanner.vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Organization } from "../model/Organization";
import { Menu } from "../model/Menu";
import { mapState } from "vuex";

@Component({
  components: {
    OrgBanner,
  },
  computed: {
    ...mapState(["activeOrg", "activeTab", "orgMenus"]),
  },
})
export default class OrgMenus extends Vue {
  private loading = true;

  private activeOrg!: Organization;
  orgMenus!: [Menu];

  private mounted() {
    console.log("RYAN MOUNTED");
  }

  private async created() {
    const id = this.$route.params.id;
    await this.$store.dispatch("loadOrg", id);
    await this.$store.dispatch("loadOrgMenus");

    this.loading = false;

    if (this.activeOrg != null) {
      document.title = this.activeOrg.name + " Menus - Beerscovery";
      const tag = document.createElement("meta");
      tag.setAttribute("name", "description");
      tag.setAttribute("content", `Browse ${this.activeOrg.name} Menus`);
      // tag as router controlled so that router removes when route changes
      tag.setAttribute("data-vue-router-controlled", "");
    }

    // TODO: 404 for Org not found
  }

  menuClicked(menu) {
    this.$store.commit("updateMenuCount", menu.id);
  }

  dateDisplay(date: any) {
    var d = new Date(1970, 0, 1); // Epoch
    d.setSeconds(date.seconds);

    return d.toLocaleDateString() + " @ " + d.toLocaleTimeString();
  }
}
